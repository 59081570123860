<template>
  <div class="animated">
    <CustomModal
      :modalTitle="$t('delete')"
      :button2Title="$t('delete')"
      :show="removeModal"
      @hide="removeModal = false"
      @onButton2Click="remove()"
    >
      {{ $t("deleteConfirm") }}
    </CustomModal>

    <b-card no-body>
      <b-card-header>
        <i class="icon-menu mr-1"></i>
        {{ $t("categories") }}
        <!-- <div class="card-header-actions">
          <BrandButton
            :text="$t('newCategory')"
            @click="add()"
            v-if="$auth.hasAuth($options, 'add')"
          ></BrandButton>
        </div> -->
      </b-card-header>
      <b-card-body>
        <v-client-table
          :columns="columns"
          :data="list"
          :options="options"
          id="dataTable"
          class="dataGrid gridDetail"
          v-if="$auth.hasAuth($options, 'list')"
        >
          <template slot="isStatic" slot-scope="props">
            <b-badge
              size="lg"
              :variant="props.row.isStatic ? 'success' : 'danger'"
              >{{ props.row.isStatic ? $t("yes") : $t("no") }}</b-badge
            >
          </template>
          <template slot="active" slot-scope="props">
            <b-badge
              size="lg"
              :variant="props.row.active ? 'success' : 'danger'"
              >{{ props.row.active ? $t("yes") : $t("no") }}</b-badge
            >
          </template>
          <template slot="buttons" slot-scope="props">
            <div class="nowrap">
              <b-button
                variant="primary icon"
                v-b-tooltip.hover
                :title="$t('edit')"
                class="mr-1 btn-brand"
                v-if="$auth.hasAuth($options, 'edit')"
              >
                <i class="fa fa-edit" @click="edit(props.row.id)"></i>
              </b-button>
              <!-- <b-button
                variant="danger icon"
                v-b-tooltip.hover
                :title="$t('delete')"
                class="mr-1 btn-brand"
                v-if="$auth.hasAuth($options, 'remove')"
              >
                <i
                  class="fa fa-trash"
                  @click="showRemoveModal(props.row.id)"
                ></i>
              </b-button> -->
            </div>
          </template>
          <div slot="child_row" class="text-left p-3" slot-scope="props">
            <b-form-group>
              <label for="symbol" class="font-weight-bold">
                {{ $t("symbol") }}:</label
              >
              <span class="notBold ml-1" id="symbol">{{
                props.row.symbol
              }}</span>
            </b-form-group>

            <b-form-group>
              <label for="styleCode" class="font-weight-bold">
                {{ $t("styleCode") }}:</label
              >
              <span class="notBold ml-1" id="styleCode">
                {{ props.row.styleCode }}</span
              >
            </b-form-group>

            <b-form-group>
              <label for="url" class="font-weight-bold">{{ $t("url") }}:</label>
              <span class="notBold ml-1" id="url">{{ props.row.url }}</span>
            </b-form-group>

            <b-form-group>
              <label for="seoKeywords" class="font-weight-bold">
                {{ $t("seoKeywords") }}:</label
              >
              <span class="notBold ml-1" id="seoKeywords">{{
                props.row.seoKeywords
              }}</span>
            </b-form-group>

            <b-form-group>
              <label for="seoDescription" class="font-weight-bold">
                {{ $t("seoDescription") }}:</label
              >
              <span class="notBold ml-1" id="seoDescription">{{
                props.row.seoDescription
              }}</span>
            </b-form-group>
          </div>
        </v-client-table>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import gridOptions from "../../shared/grid-options";
import moment from "moment";
import http from "../../shared/http";
import toast from "../../shared/toast-helper";
import { ClientTable } from "vue-tables-2";
import utility from "../../shared/utils/screen-helper";
import CustomModal from "../../widgets/form/CustomModal";
import BrandButton from "../../widgets/form/CustomBrandButton";

export default {
  name: "CategoryList",
  components: {
    ClientTable,
    CustomModal,
    BrandButton,
  },
  created() {
    this.getList();
  },
  methods: {
    getList() {
      let path = "/categories/getlist";
      http.get(path).then((response) => {
        if (response) this.list = response;
        utility.scrollToTop();
      });
    },
    add() {
      this.$router.push({ path: "category-add" });
    },
    edit(id) {
      this.$router.push({ path: `category-add/${id}` });
    },
    showRemoveModal(id) {
      this.removeModal = true;
      this.selectedId = id;
    },
    remove() {
      http
        .post(`categories/delete?categoryId=${this.selectedId}`)
        .then((response) => {
          if (response.success) {
            this.getList();
            this.removeModal = false;
            this.selectedId = 0;
            toast.success(response.message);
          }
        });
    },
  },
  data() {
    return {
      list: [],
      removeModal: false,
      selectedId: 0,
      columns: [
        "categoryName",
        "parentCategoryName",
        "isStatic",
        "active",
        "createdAt",
        "buttons",
      ],
      options: {
        ...gridOptions.getAll(),
        debounce: 100,
        perPageValues: [10, 25, 50, 100],
        headings: {
          categoryName: this.$t("categoryName"),
          parentCategoryName: this.$t("parentCategoryName"),
          isStatic: this.$t("isStatic"),
          active: this.$t("active"),
          createdAt: this.$t("createdAt"),
          buttons: this.$t("operations"),
        },
        orderBy: {
          column: "id",
          ascending: false,
        },
        templates: {
          createdAt: (h, row) =>
            moment(row.createdAt).format("DD/MM/YYYY HH:mm"),
        },
      },
    };
  },
};
</script>

<style lang="scss" scoped>
.notBold {
  font-weight: 100;
}
</style>